
.page-footer,
.homepage-footer {
	text-align: center;
	background-color: $footer-bg-color;
	color: $footer-color;
    padding: 3.5em 0 2em;

	@include link {
		color: $footer-color;
	}
}
