
//Colors

$header-bg-color: #700b29;
$header-color: #ffffff;

$main-nav-bg-color: #ffffff;
$main-nav-color: #000000;
$main-nav-button-bg: #d8c3ac;
$main-nav-button-color-hover: #ffffff;
$main-nav-button-bg-hover: #6c263b;

$search-color: #222322;
$search-button-bg-color:#700b29;

$blender-arrow-color: #700b29;

$map-marker-color: #700b29;

$contact-bg-color: rgba(#ffffff, .8);
$contact-color: #212121;
$more-button-color: #ffffff;
$more-button-bg-color: #8c2a3f;
$more-button-border-color: #8c2a3f;

$social-networks-bg-color: #700b29;
$social-networks-color: #ffffff;

$guide-ico-bg-color: #d8c3ac;

$color-h2: #700b29;
$color-h3: #700b29;

$color-anchors: #6c263b;
$color-anchors-over: #700b29;

$main-aside-bg-color: #700b29;
$main-aside-color: #ffffff;

$news-heading-color: $color-h2;
$news-date-color: #1d1d1d;
$news-title-color: #1d1d1d;
$news-color: #1d1d1d;
$news-left-line-bg-color: #bea68b;
$news-right-line-bg-color: #8c2a3f;

$events-heading-color: $color-h2;
$events-calendar-color: #d3d3d3;
$events-date-color: #700b29;
$events-date-slash-color: #700b29;
$events-title-color: #1d1d1d;
$events-color: #1d1d1d;
$events-line-bg-color: #d3d3d3;

$board-heading-color: $color-h2;
$board-blur-bg: #ffffff;
$board-color: #1d1d1d;
$board-calendar-from-color: #5b6365;
$board-more-bg-color: #ffffff;
$board-more-color: #1d1d1d;

$breadcrumbs-bg-color: $main-nav-bg-color;
$breadcrumbs-color: $search-color;
$breadcrumbs-color-active: $main-nav-button-bg-hover;

$icon-links-bg-color: #ffffff;
$icon-links-title-color: #700b29;
$icon-links-color: #1d1d1d;
$icon-links-ico-color: #700b29;

$additional-bg-color: #ffffff;
$additional-color: #2d2d2d;

$contact-form-input-color: #2d2d2d;
$contact-form-input-bg-color: #ffffff;
$contact-form-submit-color: #ffffff;
$contact-form-submit-bg-color: #700b29;
$contact-form-submit-border-color: #700b29;

$footer-bg-color: #700b29;
$footer-color: #ffffff;

