
.contact-form {
	padding-top: 0;

	.form__section {
		margin-bottom: .5em;
	}

	&__input,
	&__textarea {
		background-color: $contact-form-input-bg-color;
		border-color: #700b29;
		color: $contact-form-input-color;
		border-radius: 1px;
	}

	&__input {
		min-height: 55px;
	}

	&__info:first-child {
		margin: .55em 0 .9em;
	}

	&__button {
		border-radius: 1px;
		color: $contact-form-input-color;
        text-transform: uppercase;
        padding: 1em 2em;
		font-weight: bold;
		display: inline-block;
        font-size: rem(14px);
        border: solid 2px $contact-form-submit-border-color;
        min-width: 180px;
        cursor: pointer;
        transition: box-shadow 0.3s ease-in-out;

		&.-primary:not([disabled]) {
            color: $contact-form-submit-color;
			font-weight: bold;
            font-size: rem(17px);
            background-color: $contact-form-submit-bg-color;
            @include link-over(&){
                box-shadow: 0 5px 20px $contact-form-input-color;
            }
		}
	}
}
